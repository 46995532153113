import {Table} from 'react-bootstrap'
import Footer from '../Footer'

function Ioscenter() {
    return(
<>
        <div>
<Table striped bordered  hover size="sm">
  <thead>
    <tr>
      <th><center><strong>< svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>  iPhone Supported Devices </strong></center></th>
      <th> <center> <strong> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tablet-fill" viewBox="0 0 16 16">
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
</svg> iPad Supported Devices</strong></center> </th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td> <center> iPhone 13, 13 Pro, 13 Pro Max</center></td>
      <td> <center> 12.9-inch iPad Pro 2022</center></td>
    </tr>
    <tr>
      <td> <center> iPhone 12, 12 Pro, 12 Pro Max</center></td>
      <td> <center> 12.9-inch iPad Pro</center></td>
    </tr>
    <tr>
    <td> <center>iPhone 11, 11 Pro, 11 Pro Max </center> </td>
    <td> <center> 11-inch iPad Pro</center> </td>
    </tr>
    <tr>
    <td> <center> iPhone XS and XS Max</center> </td>
    <td> <center> 10.5-inch iPad Pro</center> </td>
    </tr>
    <tr>
    <td> <center>iPhone XR </center> </td>
    <td> <center> 9.7-inch iPad Pro</center> </td>
    </tr>
    <tr>
    <td> <center>iPhone X </center> </td>
    <td> <center> iPad (6th Gen)</center> </td>
    </tr>
    <tr>
    <td> <center>iPhone 8 </center> </td>
    <td><center> iPad (5th Gen)</center> </td>
    </tr>
    <tr>
    <td> <center> iPhone 8 Plus</center></td>
    <td><center>iPad Mini (5th Gen) </center> </td>
    </tr>
    <tr>
    <td> <center>iPhone 7 ,7 Plus </center></td>
    <td> <center>iPad Mini 4 </center></td>
    </tr>
    
   
  </tbody>
</Table>
        </div>

        <div>
<Table striped bordered  hover size="sm">
  <thead>
    <tr>
      <th><center><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-smartwatch" viewBox="0 0 16 16">
  <path d="M9 5a.5.5 0 0 0-1 0v3H6a.5.5 0 0 0 0 1h2.5a.5.5 0 0 0 .5-.5V5z"/>
  <path d="M4 1.667v.383A2.5 2.5 0 0 0 2 4.5v7a2.5 2.5 0 0 0 2 2.45v.383C4 15.253 4.746 16 5.667 16h4.666c.92 0 1.667-.746 1.667-1.667v-.383a2.5 2.5 0 0 0 2-2.45V8h.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H14v-.5a2.5 2.5 0 0 0-2-2.45v-.383C12 .747 11.254 0 10.333 0H5.667C4.747 0 4 .746 4 1.667zM4.5 3h7A1.5 1.5 0 0 1 13 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 3 11.5v-7A1.5 1.5 0 0 1 4.5 3z"/>
</svg>  WatchOS 7 Supported Devices </strong></center></th>
      <th> <center> <strong> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tv" viewBox="0 0 16 16">
  <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z"/>
</svg>  tvOS 15 Supported Devices</strong></center> </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td> <center>Apple Watch Series 3</center></td>
      <td> <center> Apple TV (4th generation)</center></td>
    </tr>
    <tr>
    <td> <center>Apple Watch Series 4 </center> </td>
    <td> <center> Apple TV 4K (5th generation)</center> </td>
    </tr>
    <tr>
    <td> <center>Apple Watch Series 5</center> </td>
    
    </tr>
    <tr>
    <td> <center>Apple Watch SE</center> </td>
   
    </tr>
    <tr>
    <td> <center>Apple Watch Series 6</center> </td>
    
    </tr>
    <tr>
    <td> <center>Apple Watch Series 7 (TBC) </center> </td>
    
    </tr>
     
  </tbody>
</Table>

        </div>
        <div>
<Table striped bordered hover  size="sm" >
  <thead>
    <center><strong><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16">
  <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
</svg> macOS Big Sur Supported Devices </strong></center>
     
    
  </thead>
  <tbody>
    
      <td> <center>MacBook (2015 and later)</center></td>
     
      <tr>
    <td> <center>MacBook Air (2013 and later) </center> </td>
    
    </tr>
    <tr>
    <td> <center>MacBook Pro (2013 and later)</center> </td>
    
    </tr>
    <tr>
    <td> <center>Mac mini (2014 and later)</center> </td>
   
    </tr>
    <tr>
    <td> <center>iMac (2014 and later)</center> </td>
    
    </tr>
    <tr>
    <td> <center>iMac Pro (2017 and later) </center> </td>
    
    </tr>
    
   
    
   
   </tbody>
 </Table>
 <div class="alert alert-warning" role="alert">
   <h4 class="alert-heading">Well done!</h4>
   <p>Welcome To ProfilesBeta.com Glad you are checking your device, even if you are confused which device you are using or You dont see your Device in the Above List you can check your Device By Simpling going to Profiles Beta Device Detector Page.</p>
   
   <p class="mb-0">Whenever you need to, be sure you can visit the <a href='/detector'> Device Detector</a> Page.</p>
 </div>
 <Footer/>
 
         </div>
 </>
 
 
     )
 }
 
 export default Ioscenter