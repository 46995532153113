
import React from 'react';
import { CardColumns, Row } from 'react-bootstrap';
import img8 from './img/img8.png';
import {Badge,Image,Card,Button} from 'react-bootstrap'
import mac from './img/img8.jpg';
import AdSense from 'react-adsense';

function Center() {
    return(

        <div style={{backgroundColor: '#F8F8F8'}}  class="inner">
<h1>Download Profiles Beta</h1>
<h3>Get what is New.</h3>

{/* imgage yahan Ayegi  */}
<div>
<Image src={ img8}fluid />
</div>
<div>

<AdSense.Google
  client='ca-pub-3121354425763384'
  slot='9076631552'
  style={{ display: 'block' }}
  layout='in-article'
  format='fluid'
/>

</div>

<div style={{ flex:1,flexDirection: 'row' ,justifyContent: 'space-between', padding:30}} >

<a style={{padding:10}} href="/about"> Leam More</a>
<a style={{padding:10, color: 'brown'}} href="/faq-common-error-installing">  FAQ ( Frequently Asked Question )<Badge  variant="warning">New</Badge></a>

</div>

<div>

<div class="alert alert-info" role="alert">
These Are <strong> Developers Beta Profiles</strong>  if you Want the Public Beta You Can Donwload it form Apples Offical <a href="https://beta.apple.com" class="alert-link">Website</a>
</div>

<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <strong>Update!</strong> Download The New Upcoming iOS 16 Beta Profile For iPhone, iPad , iPod , Mac 
  <Badge variant="primary">   New</Badge>

    
    </div >

<div  >

<Card  style={{ flex:1, flexDirection: 'row', alignItems: 'center', alignContent: 'center',backgroundImage: "linear-gradient(to right, #f46b45, #753a88)" ,height: 300}} >

<Image src={ mac} style={{maxHeight: 140 ,flex:2}} fluid/>


  <Card.Body>
    <Card.Title>iOS 16 Beta Profile</Card.Title>
    <Card.Text>
      Check if Your Device is Suppored For iOS 16 Beta Profile.
    </Card.Text>
    <Button variant="primary" href='/ios-16-beta-supported-devcie'>iOS 16 Supported Devices</Button>
  </Card.Body>
</Card>
    </div>     

</div>
<div>
<AdSense.Google
  client='ca-pub-3121354425763384'
  slot='1899895444'
  style={{ display: 'block' }}
  format='auto'
  responsive='true'
  layoutKey='-gw-1+2a-9x+5c'
/>
</div>
        </div>


    )
}

export default Center;