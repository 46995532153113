import allow from '../img/allow1.png'
import pic from '../img/pic2.png'
import pic2 from '../img/installpic.png'
import pic3 from '../img/pic3.png'
import {Image} from 'react-bootstrap'
import Footer from '../Footer'
function WatchCenter() {
    return(
    <>
<div >
<figure class="text-center " >
  <blockquote class="blockquote">
    <p>watchOS 7 beta profile has started downloading.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>Make sure you open beta.iOSLift.com in Safari browser and your pairing device has been updated to iOS 15.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>You will see the following prompt:</p>
  </blockquote>
 </figure>

</div>
<div class="text-center ">
      <Image   src={allow} fluid  />
      </div>
      <figure class="text-center   ">
  <blockquote class="blockquote  ">
    <p>Tap <strong>Allow </strong> from the popup.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>Then follow the prompts to install configuration beta in Settings app and restart your device.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>Make sure your Apple Watch is unlocked during installation.</p>
  </blockquote>
  </figure>


   < div class="text-center ">
   </div>
   <blockquote class="blockquote">
    <p class="text-center">After your device has restarted, you can get beta update in Watch app - General - Software Update.

</p>
  </blockquote>
  < div class="text-center ">

  </div>
  < div class="text-center " fluid style={{marginTop:10}}>
  <div class="alert alert-success   " role="alert">
  
  <p ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg> Currently, you cannot downgrade watchOS. So make sure you know what you're doing.</p>

</ div> 
</ div>


   </> 
  
   )
    
}export default WatchCenter