import Logo from '../img/Faq.png'
import Header from '../Header'
import {Image} from 'react-bootstrap'
import Footer from '../Footer'
import Faqcenter from './Faqcenter'
function Faqhead() {
 
    
    return(

        <>

        <Header/>
        
        <div  style={{backgroundColor: '#E8E8E8',textAlign:'center' }}>
            <div  >
            <h1  style={{fontsize:40 }}><strong > Frequently Asked Question</strong> </h1>
            <h3>Know More About iOS Beta Profile </h3>
        <Image  src={Logo} fluid   />
        </div>
    
        </div>
        <div style={{marginTop:50}}>
        
        </div>
            <Faqcenter/>
         <Footer/>
        </>
        
    )
}

export default Faqhead