
import './App.css';

import ReactGa from 'react-ga'
import DHead from './Devices/DHead'
import { useEffect } from 'react';
import HomePage from './HomePage'
import Dectector from './Detector/Detector'
import HttpsRedirect from 'react-https-redirect';
import PagenotFound from './PageNotFound/Pagenotfound'
import About from './About/About'
import IosInstallHead from "./InstallPage/installhead";
import Ios15beta from './ios15Beta/ios15betahead'
import Ios16beta from './ios16beta/ios16betahead';
import WatchHead from './WatchOS/WatchHead'
import iPswHead from './IPSWFiles/iPswHead';
import Faqhead from './FAQ/Faqhead';
import DonateHead from './Donate/DonateHead';
import { Adsense } from '@ctrl/react-adsense';
import {



  BrowserRouter as Router,
 Switch,
 Route

} from 'react-router-dom'
function App() {
//UA-81746914-4
  useEffect(()=>{
ReactGa.initialize('UA-81746914-4')
ReactGa.pageview(window.location.pathname + window.location.search)
},[])

  return (
    <>

<HttpsRedirect>
<Router>
    
     <Switch> 
       <Route path='/' exact component={HomePage}/>
       <Route path='/detector'  component={Dectector}/>
       <Route path='/support-devices'  component={DHead}/>
       <Route path='/ios-15-beta-supported-devcies' component={Ios15beta}/>
       <Route path='/ios-16-beta-supported-devcie' component={Ios16beta}/>
       <Route path='/download-beta-install-ios-15-beta'  component={IosInstallHead}/>
       <Route path='/install-watchos-beta'  component={WatchHead}/>
       <Route path='/about'  component={About}/>
       <Route path='/faq-common-error-installing'  component={Faqhead}/>
       <Route path='/Support-Us'  component={DonateHead}/>
       <Route path='/ipsw.me'  component={iPswHead}/>
      <Route  path = '*' component={PagenotFound}/>
   

     </Switch>
   </Router>
</HttpsRedirect>
 




     

  
     
  
    </>
  );
}

export default App;
