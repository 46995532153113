
import reactDom from 'react-dom';
import '.././node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, {  } from 'react';
import {Button,Modal,Accordion,Card,Table ,Badge} from 'react-bootstrap'; 

import './Code.css'
import Airpods from './img/airpods.png';
import ipad from './img/ipados.jpg';
import ios from './img/ios14.png';
import mac from './img/macbook.PNG';
import tvos from './img/tvOS.png';
import watchos from './img/watchos.png';
import { useState } from 'react';
import {Helmet} from 'react-helmet'


function MainPage(){
    const [lgShow, setLgShow] = useState(false);
    const [ipadShow, setipadShow] = useState(false);
    const [TVShow, setTvShow] = useState(false);
   

    const [iOSShow, setiOSShow] = useState(false)    
    const [IpadiosShow, setipadiosShow] = useState(false)    
    const [MacShow, setmacShow] = useState(false)    
    const [WatcShow, setWatchShow] = useState(false)    
    const [TVOShow, setTVOSShow] = useState(false)    
    const [InfoShow, setInfoShow] = useState(false)
return(



    <>
  <Helmet>
 
<title>Profile Beta </title>
<meta name="description" content="Download Newest Profile Beta for your iPhone ,iPadOS , iPodOS and MacOS  " data-react-helmet="true"/>
<meta name="keywords" content="Profile Beta , ios 16 Beta Profile, Upadte iOS 14, iOS 15 iOS 16 iOS 17 IPSW Beta ,iOS Update Profile, ios profielbeta" />

<meta property="og:url" content="https://beta.ioslift.com"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Profile Beta "/>
<meta property="og:description" content="Download Newest Profile Beta for your iPhone,iPadOS,iPodOS and MacOS "/>
<meta property="og:image" content="http://beta.ioslift.com/img/og20.jpg"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="beta.ioslift.com"/>
<meta property="twitter:url" content="https://beta.ioslift.com"/>
<meta name="twitter:title" content="Profile Beta "/>
<meta name="twitter:description" content="Download Newest Profile Beta for your iPhone iPad and iPod "/>
<meta name="twitter:image" content="http://beta.ioslift.com/img/og20.jpg" />


       
        </Helmet>

<div style={{paddingTop:100}}>



<div class='  card justify-content-center mx-auto row flex-row p-2'  id="iphone">

<div class="card box d-flex align-items-start flex-col mx-3 mb-3" style={{ backgroundImage: "linear-gradient(to right, #ff6600, #ff9933)", width: '21rem' ,height:'40rem',borderRadius:'50px'}} >


  <div style={{  borderRadius:'50px'}} class="card-body">
  <div style={{paddingBottom: 100}} class="inner">
<img src={ipad}className=" card-img-center" alt='Apple iOS 16 ' height='200rem' style={{paddingTop:40}} /> 
</div >
    <h2   class="card-title">iPadOS</h2>
    <p class="card-text" style={{textAlign: 'center'}}> Update your apps to use new features, and test your apps against API changes .</p>
    <a  download class="btn btn-primary" onClick={() => setipadiosShow(true)}>Download</a>   <a onClick={() => setipadShow(true)}  class="btn btn-secondary" style={{marginTop:3 ,marginRight:10}}>IPSW </a>
  </div>

</div>


<div class="card box mx-3 mb-3" style={{ backgroundImage: `linear-gradient(to right, rgba(0, 204, 43, 94), rgba(0, 133, 255, 1))`, width: '21rem' ,height:'40rem',borderRadius:'50px'}} >

  <div style={{ }} class="card-body domain-gradient bg-gradient-purple light-color">
  <div style={{paddingBottom: 100}} class="inner">
<img src={ios}className=" card-img-top" alt='IOS 15 Beta Profile ' height='260rem' /> 
</div>
    <h2 class="card-title">iOS 16</h2>
    <p class="card-text">Download The New iOS 16 Developer Beta Update for Your iPhone .</p>
    <a  download class="btn btn-primary" onClick={() => setiOSShow(true)} >Download</a>   <a onClick={() => setLgShow(true)} class="btn btn-secondary" style={{marginTop:3 ,marginRight:10}}>IPSW </a>
  </div>
  </div>
  

  <div  class="card box  mx-3 mb-3 flex" style={{ backgroundImage: "linear-gradient(to right, #ffd89b, #19547b)", width: '21rem' ,height:'40rem',borderRadius:'50px'}} >
 
  <div  class="card-body">
  <div  style={{paddingBottom: 100}} class="inner">
<img src={mac}className=" card-img-top" alt='faheem ali ' height='150rem' style={{alignContent: 'center'}} /> 
</div>
    <h2 class="card-title">MacOS</h2>
    <p class="card-text">Download The New MacOS Developer Beta Profile Update for Your iPhone .</p>
    <a   download class="btn btn-primary" onClick={() => setmacShow(true)}>Download</a>
  </div>
  </div>
  
 







 

</div>


<div>



<div class='card justify-content-center mx-auto  row flex-row p-2 bd-highlight '>

<div class="card box d-flex align-items-start flex-col mx-3 mb-3" style={{ backgroundImage: "linear-gradient(to bottom,#ffffff , #ff00ff)",width: '21rem' ,height:'40rem',borderRadius:'50px' }} >

  <div  class="card-body">
  <div  style={{paddingBottom: 100}} class="inner">
<img src={tvos}className=" card-img-top" alt='TVOS 15 Beta' height='260rem' style={{paddingTop:40}}/> 
</div>
    <h2 class="card-title">tvOS</h2>
    <p class="card-text">Download The New TvOS Developer Beta Update for Your iPhone .</p>
    <a  download class="btn btn-primary"onClick={() => setTVOSShow(true)} >Download</a>   <a  onClick={() => setTvShow(true)} class="btn btn-secondary" style={{marginTop:3 ,marginRight:10}}>IPSW </a>
  </div>

</div>


<div class="card box mx-3 mb-3" style={{ backgroundImage: "linear-gradient(to right, #0099ff, #ff66ff)" ,width: '21rem' ,height:'40rem',borderRadius:'50px'}} >

  <div  class="card-body">
  <div  style={{paddingBottom: 100}} class="inner">
<img src={watchos}className=" card-img-top" alt='faheem ali ' height='260rem' style={{paddingTop:40}}/> 
</div>
    <h2 class="card-title">WatchOS</h2>
    <p class="card-text">Download The New WatchOS Developer Beta Update for Your iPhone .</p>
    <a  download class="btn btn-primary" onClick={() => setWatchShow(true)}>Download</a>  
  </div>
  </div>
  

  <div class="card box  mx-3 mb-3" style={{ backgroundImage: "linear-gradient(to right, #ff9900, #ff99cc)",width: '21rem' ,height:'40rem',borderRadius:'50px' }} >
 
  <div   class="card-body">
  <div  style={{paddingBottom: 100}} class="inner">
<img src={Airpods}className=" card-img-top" alt='faheem ali ' height='260rem' style={{paddingTop:40}}/> 
</div>
  <h2 class="card-title">AirPods Pro</h2>
 
   
    <p class="card-text">Download the New Beta Profile for HomePOD.</p>
    <a download class="btn btn-primary" onClick={() => setInfoShow(true)}>Download</a>
  </div>
  </div>
  
 

</div>





</div>




{/* Yahan Pay Sare Download wale Dailog Box Ayegay */}


<div>
  <Modal
        
        show={iOSShow}
        onHide={() => setiOSShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC </p>
      <p> Connect iPhone and your computer with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setiOSShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/iOS_15_Beta_Profile.mobileconfig" download class="btn btn-primary"onClick={() => setiOSShow(false)} >Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>

  <div>
  <Modal
        
        show={IpadiosShow}
        onHide={() => setipadiosShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC </p>
      <p> Connect iPhone and your computer with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setipadiosShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/iOS_iPadOS_15_Beta_Profile.mobileconfig" download class="btn btn-primary" onClick={() => setipadiosShow(false)} >Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>
  <div>
  <Modal
        
        show={MacShow}
        onHide={() => setmacShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC / Mac </p>
      <p> Connect Apple Device  and your Pc/Mac with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setmacShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/macOSDeveloperBetaAccessUtility.dmg" download class="btn btn-primary" onClick={() => setmacShow(false)}>Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>

  <div>
  <Modal
        
        show={WatcShow}
        onHide={() => setWatchShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC </p>
      <p> Connect iPhone and your computer with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setWatchShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/tvOS_15_Beta_Profile.mobileconfig" download class="btn btn-primary"onClick={() => setWatchShow(false)} >Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>

  <div>
  <Modal
        
        show={TVOShow}
        onHide={() => setTVOSShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC </p>
      <p> Connect iPhone and your computer with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setTVOSShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/tvOS_15_Beta_Profile.mobileconfig" download class="btn btn-primary" onClick={() => setTVOSShow(false)}>Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>


  <div>



  <Modal
        
        show={InfoShow}
        onHide={() => setInfoShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
  <Modal.Dialog>
    <Modal.Header closeButton>
      <Modal.Title>General Steps Before Downloading</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <p>Before Downloading The Beta Profile Make sure to Backup your Device First
      Back up iPhone using your Windows PC </p>
      <p> Connect iPhone and your computer with a cable. </p>
      <p>In the iTunes app on your PC, click the iPhone button near the top left of the iTunes window. </p>
      <p>Click Back Up Now (below Backups).</p>

    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setInfoShow(false)} >Close</Button>
      <a  href="../ProfilesBeta/iOSAirPodsProSeed.mobileconfig" download class="btn btn-primary" onClick={() => setInfoShow(false)}>Download</a>
    </Modal.Footer>
  </Modal.Dialog>
  </Modal>
  </div>





{/* Yahan End Hongay Dailogbox  */}

<div>

<Modal
        size="lg"
        show={TVShow}
        onHide={() => setTvShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Download Firmware iPad 
          </Modal.Title>
         
        </Modal.Header>
        <Modal.Body><Accordion defaultActiveKey="0">
  <Card>
  <div class="alert alert-warning" role="alert">
  Check Out of offical Website ! <a href="https://ioslift.com" class="alert-link">iOSlift</a>
</div>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      <strong> <strong>Apple TvOS</strong></strong> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
      <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">tvOS 15 beta 3</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-64090/49DF82E7-ABC1-4B77-AA22-9F9E66DEC8C8/AppleTV5,3_15.0_19J5304d_Restore.ipsw">Download</a></td>
</tr><tr>

</tr><tr>

</tr> </tbody>
</table>
</Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>

<h1>Frequently Asked Questions </h1>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>How do I install this?
</th>
    
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Just search for it on Google, you'll find every post you need.

</td>
      
     
    </tr>
    <tr>
      <td>What if I download the wrong file?</td>
      
     
    </tr>
    <tr>
      <td>iTunes will stop the process if it detects that you have the wrong file for your device.

</td>
     
    </tr>
  </tbody>
</Table>

</Modal.Body>
      </Modal>


</div>


<div>
{/* Yahan Pay IPad k OS ki Data Ayegi  */}
<Modal
        size="lg"
        show={ipadShow}
        onHide={() => setipadShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Download Firmware iPad 18E5140k (iPhone11,2)
          </Modal.Title>
         
        </Modal.Header>
        <Modal.Body><Accordion defaultActiveKey="0">
  <Card>
  <div class="alert alert-warning" role="alert">
  Check Out of offical Website ! <a href="https://ioslift.com" class="alert-link">iOSlift</a>
</div>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      <strong> iPad Air</strong> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
      <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPad Air 2</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63564/DFD45A7F-1B2D-4AF6-AB23-D04354412F69/iPad_64bit_TouchID_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Air (3rd generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63287/09A56A83-AF55-4CEF-B0A4-CD439ECACA4E/iPad_Spring_2019_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Air (4th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63227/B9F40759-307E-4D8D-BB8A-3811BA353A4F/iPad_Fall_2020_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table>
</Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      <strong>iPad</strong>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPad (5th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62988/B695C5E2-6BA1-4E0D-8EFE-F9CECB51984D/iPad_64bit_TouchID_ASTC_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad (6th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62988/B695C5E2-6BA1-4E0D-8EFE-F9CECB51984D/iPad_64bit_TouchID_ASTC_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad (7th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63164/53F0A378-F3FF-4A5F-8E8E-8BF4C529BAD7/iPad_10.2_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad (8th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63376/94B7545E-25C0-42B2-8D55-55526B230C76/iPad_10.2_2020_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table>

      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
      <strong>iPad Pro</strong>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>

      <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPad Pro (12.9-inch)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63496/54779C10-B59F-40A0-AB05-146CB0AC85FF/iPadPro_12.9_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (9.7-inch)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63580/F1202A1F-24F2-4AE7-9CD3-AD15296AFCDB/iPadPro_9.7_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (12.9-inch) (2nd generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62983/1D0C81F4-F7FF-41AF-896E-D5FA1D892AEF/iPad_Pro_A12X_A12Z_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (10.5-inch)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2020WinterSeed/fullrestores/071-03541/355B4A5F-8217-4701-B196-25328F97FBF6/iPad_Pro_HFR_14.5_18E5140k_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (11-inch)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2020WinterSeed/fullrestores/071-03628/AB6CBDB9-DF99-4ADF-86F9-3C5E780C54B8/iPad_Pro_A12X_A12Z_14.5_18E5140k_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (12.9-inch) (3rd generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62983/1D0C81F4-F7FF-41AF-896E-D5FA1D892AEF/iPad_Pro_A12X_A12Z_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (11-inch) (2nd generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2020WinterSeed/fullrestores/071-03628/AB6CBDB9-DF99-4ADF-86F9-3C5E780C54B8/iPad_Pro_A12X_A12Z_14.5_18E5140k_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad Pro (12.9-inch) (4th generation)</td>
 <td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62983/1D0C81F4-F7FF-41AF-896E-D5FA1D892AEF/iPad_Pro_A12X_A12Z_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table>

      </Card.Body>
    </Accordion.Collapse>
  </Card >
  <Card > 
    <Accordion.Toggle as={Card.Header} eventKey="3">
    <strong>iPad Mini</strong>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body> 
        

        <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPad mini 4</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63564/DFD45A7F-1B2D-4AF6-AB23-D04354412F69/iPad_64bit_TouchID_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPad mini (5th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-63287/09A56A83-AF55-4CEF-B0A4-CD439ECACA4E/iPad_Spring_2019_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>

<h1>Frequently Asked Questions </h1>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>How do I install this?
</th>
    
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Just search for it on Google, you'll find every post you need.

</td>
      
     
    </tr>
    <tr>
      <td>What if I download the wrong file?</td>
      
     
    </tr>
    <tr>
      <td>iTunes will stop the process if it detects that you have the wrong file for your device. The only thing it will cost you is another download, so please read carefully.

</td>
     
    </tr>
  </tbody>
</Table>
</Modal.Body>
      </Modal>


</div>



</div>

<Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
          Download Firmware iOS 18E5140k (iPhone11,2)
          </Modal.Title>
         
        </Modal.Header>
        <Modal.Body><Accordion defaultActiveKey="0">
  <Card>
  <div class="alert alert-warning" role="alert">
  Check Out of offical Website ! <a href="https://ioslift.com" class="alert-link">iOSlift</a>
</div>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      iPhone
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body><table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPhone 8</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone_4.7_P3_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 8 Plus</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone_5.5_P3_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone X</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone103iPhone106_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone XR</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone118_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone XS</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone112iPhone114iPhone116_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone XS Max</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone112iPhone114iPhone116_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 11</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://download.developer.apple.com/WWDC_2022/iOS_16_beta/iPhone121_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 11 Pro</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone123iPhone125_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 11 Pro Max</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2020WinterSeed/fullrestores/071-03570/77792549-99D5-4057-922A-45E31232D52E/iPhone11,2,iPhone11,4,iPhone11,6,iPhone12,3,iPhone12,5_14.5_18E5140k_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone SE (2nd generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2020WinterSeed/fullrestores/071-03538/9758FD69-27B5-4844-BCA7-A6F5B43B6798/iPhone12,8_14.5_18E5140k_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 12 mini</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone131_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 12</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone132iPhone133_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 12 Pro</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone132iPhone133_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 12 Pro Max</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone134_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 13 mini</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone144_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 13</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone145_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 13 Pro</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone142_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr><tr>
<td class="col-11">iPhone 13 Pro Max</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://developer.apple.com/services-account/download?path=/WWDC_2022/iOS_16_beta/iPhone143_16.0_20A5283p_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table>
</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      iPod
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body><table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11">iPod touch (7th generation)</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" href="https://updates.cdn-apple.com/2021SummerSeed/fullrestores/071-62997/DCBD1A15-E701-4D02-9253-A3AE92CFECEB/iPodtouch_7_15.0_19A5297e_Restore.ipsw">Download</a></td>
</tr> </tbody>
</table></Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>

<h1>Frequently Asked Questions </h1>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>How do I install this?
</th>
    
     
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Just search for it on Google, you'll find every post you need.

</td>
      
     
    </tr>
    <tr>
      <td>What if I download the wrong file?</td>
      
     
    </tr>
    <tr>
      <td>iTunes will stop the process if it detects that you have the wrong file for your device. The only thing it will cost you is another download, so please read carefully.

</td>
     
    </tr>
  </tbody>
</Table>
</Modal.Body>
      </Modal>


<div>

</div>
    
</>


)

}
export default MainPage;