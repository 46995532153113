import Header from '../Header'
import Ldevice from '../img/LogoSupport.png'
import Ios16center from './ios16betacenter'
import {Image} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import AdSense from 'react-adsense';

function Ios16beta() {

return(

<>

<Helmet>
 
<title>iOS 16 Supported Devices </title>
<meta name="description" content="Check weather your device is suported for iOS 16 The Big Update By Apple in the Following iOS 16 Beta Devices List "data-react-helmet="true"/>
<meta name="keywords" content="Profile Beta , ios 16 Beta Profile, Upadte To iOS 16, Download iOS 16 IPSW Beta,ios 16 supported Devices" />

<meta property="og:url" content="https://beta.ioslift.com/ios-16-beta-supported-devcies"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="iOS 16 Beta Supported Devices "/>
<meta property="og:description" content="Check weather your device is suported for iOS 15 The Big Update By Apple in the Following iOS 16 Beta Devices List "/>
<meta property="og:image" content="http://beta.ioslift.com/img/ls.png"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="beta.ioslift.com"/>
<meta property="twitter:url" content="https://beta.ioslift.com/ios-16-beta-supported-devcies"/>
<meta name="twitter:title" content="iOS 16 Beta Supported Devices "/>
<meta name="twitter:description" content="Check weather your device is suported for iOS 16 The Big Update By Apple in the Following iOS 16 Beta Devices List"/>
<meta name="twitter:image" content="http://beta.ioslift.com/img/ls.png" />


       
        </Helmet>


<Header/>

<div style={{backgroundColor: '#F8F8F8',textAlign:'center'}}>
<h1 style={{fontsize:40 }} >

<strong>
  Supported Devices For iOS 16</strong>
</h1>
<h3>Check Your Device.</h3>
<Image src={Ldevice} fluid/>

</div>
<div style={{marginTop:50}}>

</div>
<div style={{textAlign:'center'}}>

    <AdSense.Google
  client='ca-pub-3121354425763384'
  slot='6523867754'
  style={{ display: 'block' }}
  format='auto'
  responsive='true'
  layoutKey='-gw-1+2a-9x+5c'
/>

    </div>
<Ios16center />
</>


)


    
}
export default Ios16beta