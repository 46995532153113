
import Header from '../Header'
import Footer from '../Footer'
import Ldevice from '../img/settingicone1.png'
import WatchCenter from './WatchCenter'
import {Image} from 'react-bootstrap'
import Logo from '../img/DeviceLogo.png'
function WatchHead() {

return(

<>

<Header/>

<div  style={{backgroundColor: '#E8E8E8',textAlign:'center' }}>
    <div  >
<Image  src={Ldevice} fluid   />
</div>

<h1  style={{fontsize:40 ,padding:20}}>

<strong >
A few more steps...</strong>
</h1>





</div>
<WatchCenter/>
<Footer/>
</>


)


    
}
export default WatchHead