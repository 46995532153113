import React from 'react'
import Header from '../Header'
import Ipswcenter from './Ipswcenter'
function iPswHead() {
    
    return(
    
        <>
        <Header/>
        <Ipswcenter />

     <div>

<h1>faheem</h1>

     </div>
        </>
    
    )
    
    } 
    export default iPswHead