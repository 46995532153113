import { Carousel} from "react-bootstrap"
import Center from "../Center"
import im0 from '../img/404.png'
import im1 from '../img/allow1.png'
import im3 from '../img/ios14.jpg'
function Ipswcenter() {
    

  return(

    <>
    <div >
  
  
    </div>
    </>
  )
}


export default Ipswcenter