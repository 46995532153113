import Header from '../Header'
import Footer from '../Footer'
import Ldevice from '../img/LogoSupport.png'
import Ioscenter from './ios15betacenter'
import {Image} from 'react-bootstrap'
import Logo from '../img/DeviceLogo.png'
import {Helmet} from 'react-helmet'
function Ios15beta() {

return(

<>

<Helmet>
 
<title>iOS 15 Supported Devices </title>
<meta name="description" content="Check weather your device is suported for iOS 15 The Big Update By Apple in the Following iOS 15 Beta Devices List "data-react-helmet="true"/>
<meta name="keywords" content="Profile Beta , ios 15 Beta Profile, Upadte iOS 15, iOS 15 IPSW Beta,ios 15 supported Devices" />

<meta property="og:url" content="https://beta.ioslift.com/ios-15-beta-supported-devcies"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Profile Beta "/>
<meta property="og:description" content="Check weather your device is suported for iOS 15 The Big Update By Apple in the Following iOS 15 Beta Devices List "/>
<meta property="og:image" content="http://beta.ioslift.com/img/ls.png"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="beta.ioslift.com"/>
<meta property="twitter:url" content="https://beta.ioslift.com/ios-15-beta-supported-devcies"/>
<meta name="twitter:title" content="Profile Beta "/>
<meta name="twitter:description" content="Check weather your device is suported for iOS 15 The Big Update By Apple in the Following iOS 15 Beta Devices List"/>
<meta name="twitter:image" content="http://beta.ioslift.com/img/ls.png" />


       
        </Helmet>


<Header/>

<div style={{backgroundColor: '#F8F8F8',textAlign:'center'}}>
<h1 style={{fontsize:40 }} >

<strong>
  Supported Devices For iOS 15</strong>
</h1>
<h3>Check Your Device.</h3>
<Image src={Ldevice} fluid/>

</div>
<div style={{marginTop:50}}>

</div>
<Ioscenter/>
</>


)


    
}
export default Ios15beta