
import Header from '../Header'
import Footer from '../Footer'
import Ldevice from '../img/settingicone1.png'
import IosInstallCenter from './installcenter'
import {Image} from 'react-bootstrap'
function IosInstallHead() {

return(

<>

<Header/>

<div  style={{backgroundColor: '#E8E8E8',textAlign:'center' }}>
    <div  >
<Image  src={Ldevice} fluid   />
</div>

<h1  style={{fontsize:40 }}>

<strong >
A few more steps...</strong>
</h1>



</div>
<div style={{marginTop:50}}>

</div>
< IosInstallCenter/>

</>


)


    
}


    

export default IosInstallHead