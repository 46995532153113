import logo from './logo.svg';
import './App.css';
import MainPage from './MainPage'
import Header from './Header'
import Center from './Center'
import Footer from './Footer'
import IOS14Home from './IOS14Beta/iOS14Home';

function HomePage() {


  return (
    <div className="App">
        <Header/>
        <Center/>
        <MainPage/>
       

      
         <Footer/>
    </div>
  );
}

export default HomePage;
