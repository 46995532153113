import allow from '../img/allow1.jpg'
import pic from '../img/pic2.png'
import pic2 from '../img/installpic.png'
import pic3 from '../img/pic3.png'
import {Image} from 'react-bootstrap'
import Footer from '../Footer'
import { Helmet } from 'react-helmet'
function IosInstallCenter() {
    return(
    <>
    <Helmet>
 
 <title>Download iOS 16 Beta Profile - Profiles Beta </title>
 <meta name="description" content="Downlaod and Install ios 15 beta profile for your iPhone , iPad, Mac and Get the New updates from Apple "data-react-helmet="true"/>
 <meta name="keywords" content="beta profiles,ios 16 beta profile, Download IOS 15 Beta Profile,ios 15 beta,install ios 15 Beta Profile" />
 
 <meta property="og:url" content="https://beta.ioslift.com/download-beta-install-ios-15-beta"/>
 <meta property="og:type" content="website"/>
 <meta property="og:title" content="Profile Beta "/>
 <meta property="og:description" content="Downlaod and Install ios 16 beta profile for your iPhone , iPad, Mac and Get the New updates from Apple"/>
 <meta property="og:image" content="http://beta.ioslift.com/img/ls.png"/>
 
 
 <meta name="twitter:card" content="summary_large_image"/>
 <meta property="twitter:domain" content="beta.ioslift.com"/>
 <meta property="twitter:url" content="https://beta.ioslift.com/download-beta-install-ios-15-beta"/>
 <meta name="twitter:title" content="Profile Beta "/>
 <meta name="twitter:description" content="Downlaod and Install ios 16 beta profile for your iPhone , iPad, Mac and Get the New updates from Apple"/>
 <meta name="twitter:image" content="http://beta.ioslift.com/img/ls.png" />
 
 
        
         </Helmet>
<div >
<figure class="text-center " >
  <blockquote class="blockquote">
    <p>iOS 16 beta profile has started downloading.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>Make sure you open beta.ioslift.com in Safari browser.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>You will see the following prompt:</p>
  </blockquote>
 </figure>

</div>
<div class="text-center ">
      <Image   src={allow} fluid  />
      </div>
      <figure class="text-center   " style={{display:'flex',flexDirection:'column'}}>
  <blockquote class="blockquote  ">
    <p>Tap <strong>Allow </strong> from the popup.</p>
  </blockquote>
  <blockquote class="blockquote">
    <p>Open <strong>Settings app - Profile Downloaded.</strong></p>
  </blockquote></figure>
 < div class="text-center ">
  <Image src={pic} fluid />
  </ div>
  <blockquote class="blockquote">
   <p class="text-center">Then follow the prompts to install configuration beta in Settings app and restart your device.</p>
   </blockquote>
   < div class="text-center ">
   <Image src={pic2} fluid class="card-img-top flex-row " alt="..."/>
   </div>
   <blockquote class="blockquote">
    <p class="text-center">After your device has restarted, you can receive beta update in  <strong>Settings - General - Software Update. </strong> </p>
  </blockquote>
  < div class="text-center ">

  <Image src={pic3} fluid class="card-img-top" alt="..."/>
  </div>
  < div class="text-center " fluid style={{marginTop:10}}>
  <div class="alert alert-success   " role="alert">
  
  <p ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg> Make sure you back up your device before installing new software, you may lose data during the update process.</p>

  <p class="mb-0  mx-auto  "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/>
</svg> You only need to install the beta profile once, the next updates will appear in the Settings app - General - Software Update.</p> 
</div>
</ div>
< div class="text-center " fluid style={{marginBlockEnd:10}}>
<a  href="#" style={{fontSize:20}}> <strong>Learn how to unenroll your devices to stop receiving developer betas ?</strong></a>
</div>
<Footer/>
   </> 
  
   )
    
}export default IosInstallCenter