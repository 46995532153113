import Logo from '../img/Donate.PNG'
import Header from '../Header'
import {Image,Accordion,Card} from 'react-bootstrap'
import Footer from '../Footer'
import { Helmet } from 'react-helmet'

function DonateHead() {
    return(
<>
<Helmet>
 
<title>Donate Some Love - Profile Beta </title>
<meta name="description" content="Donate Some Love BetaProfile Can Work 24/7 As Being a Pakistani its Hard to stugle " data-react-helmet="true"/>
<meta name="keywords" content="Donate Love , Donation Amout Profile Beta, Beta Needs Donation,iOS Update Profile, ios profielbeta" />

<meta property="og:url" content="https://beta.ioslift.com/donatelove"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Donate Some Love - Profile Beta "/>
<meta property="og:description" content="Donate Some Love to keep Servers Up and Ruuning To Get Upadtes "/>
<meta property="og:image" content="http://beta.ioslift.com/img/Donate.PNG"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="beta.ioslift.com/donatelove"/>
<meta property="twitter:url" content="https://beta.ioslift.com/donatelove"/>
<meta name="twitter:title" content="Donate Some Love - Profile Beta "/>
<meta name="twitter:description" content="Donate Some Love to keep Servers Up and Ruuning To Get Upadtes"/>
<meta name="twitter:image" content="http://beta.ioslift.com/img/Donate.PNG" /></Helmet>
        <div >
            <Header/>
        <div style={{backgroundColor: '#E8E8E8',textAlign:'center' }}>
            <h1 >Donate Some Love</h1>
            <h3 >Working 24/7 To Get You Updated</h3>
        <Image  src={Logo} fluid   />

        </div>

        <div style={{paddingTop:50, paddingBottom:50, background: 'yellowgreen'}}>
        <Accordion defaultActiveKey="0">
  <Card>
  
    <Accordion.Toggle as={Card.Header} eventKey="0">
      <strong> <strong>Donate Some Love with Profile Beta </strong></strong> 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
      <table class="table table-striped mb-0">
<tbody><tr>
<td class="col-11"> <b>Jazz Cash </b> Title : Faheem Ali</td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" >+92-3052219550</a></td>
</tr><tr>
<td class="col-11"> <b> EasyPassia </b> Title: Faheem Ali </td>
<td class="col-1"> <a class="ipsw-button-down rounded-sm text-nowrap" >+92-3052219550</a></td>
</tr></tbody>
</table>
</Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>
</div>
        </div>
        <Footer/>
        </>
    )
}

export default DonateHead